
import { GTM_COMMUNITY_AD_PARAMETERS } from '@/constants/community/gtm';

import { baseUrl, cdnBaseUrl } from '@/plugins/constants';
import { getTimeInAsia } from '@/plugins/dayjs';

import { moveToLaravelPage, removeBaseUrl } from '@/utils/utils';

import { CAMP_CHARM_ITEM, EDU_USER_COMPLETION, EDU_USER_MATCH_INFO, mapGettersOfCamp } from '@/store/camp';
import {
	AFFILIATE_UNIV_EXPOSURE_DATA,
	CASE_CATEGORY_GROUP_ID,
	CASE_GTM_CATEGORY,
	mapGettersOfCase,
} from '@/store/case';
import { GET_USER_META_INTEREST_DEPARTMENT, mapGettersOfUserMeta } from '@/store/userMeta';

import CaseListEduCampBannerContents from '@/components/case/list/main/atoms/CaseListEduCampBannerContents.vue';

import eduCampMixin from '@/mixins/eduCampMixin';
import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import localStorageIdMixin from '@/mixins/localStorageIdMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

import Skeleton from '@/src/components/Other/Skeleton.vue';

export default {
	name: 'CaseListEduCampBanner',
	mixins: [
		layoutMixin,
		userInfoMixin,
		gtmTriggerMixin,
		localStorageIdMixin,
		routeMixin,
		eduCampMixin,
		communityGtmTriggerMixin,
	],
	data() {
		return {
			cdnBaseUrl,
			baseUrl,
		};
	},

	computed: {
		...mapGettersOfUserMeta([GET_USER_META_INTEREST_DEPARTMENT]),
		...mapGettersOfCamp([EDU_USER_MATCH_INFO, EDU_USER_COMPLETION, CAMP_CHARM_ITEM]),
		...mapGettersOfCase([CASE_CATEGORY_GROUP_ID, CASE_GTM_CATEGORY, AFFILIATE_UNIV_EXPOSURE_DATA]),
		nickname() {
			return this.userNickname || '멘티';
		},
		isTimeline() {
			return this[CASE_CATEGORY_GROUP_ID] === null;
		},
		isLoading() {
			return this.isTimeline && !this.CAMP_CHARM_ITEM.edu_mentor;
		},
		computedUrl() {
			if (!this[CAMP_CHARM_ITEM]) {
				return;
			}
			if (this.isIncludesKdcPrimaryKeyword) {
				return `/kdigital/제조∙연구/반도체-공정설계-fab-mass-데이터-분석-및-process-integratio`;
			}
			if (this.isIncludesKdcSecondaryKeyword) {
				return '/kdigital/제조∙연구/개발자를-위한-반도체-sw개발-기초-디바이스-드라이버-개발';
			}
			if (this.isPt) {
				return removeBaseUrl(this.ptDetailUrl);
			}
			return removeBaseUrl(this[CAMP_CHARM_ITEM].link);
		},
		ptDetailUrl() {
			if (this[CAMP_CHARM_ITEM].id <= 12) {
				return this[CAMP_CHARM_ITEM].old_url;
			}
			return this[CAMP_CHARM_ITEM].url;
		},
		isIncludesKdcPrimaryKeyword() {
			if (!this[GET_USER_META_INTEREST_DEPARTMENT]) {
				return false;
			}
			return this[GET_USER_META_INTEREST_DEPARTMENT].includes('공정설계');
		},
		isIncludesKdcSecondaryKeyword() {
			if (!this[GET_USER_META_INTEREST_DEPARTMENT]) {
				return false;
			}
			return this[GET_USER_META_INTEREST_DEPARTMENT].includes('SW개발');
		},
		isPt() {
			// id는 실무PT에만 있음
			return !!this[CAMP_CHARM_ITEM]?.id;
		},
		mentorNickname() {
			if (this.isIncludesKdcPrimaryKeyword) {
				return '이OO';
			}
			if (this.isIncludesKdcSecondaryKeyword) {
				return '뚠뚠개미';
			}
			return this.isPt ? this[CAMP_CHARM_ITEM].mentor?.nickname : this[CAMP_CHARM_ITEM].edu_mentor.nickname;
		},
		avatarImage() {
			return this.isPt ? this[CAMP_CHARM_ITEM].camp_image : this[CAMP_CHARM_ITEM].title_img;
		},
		category() {
			if (this.isIncludesKdcPrimaryKeyword) {
				return '공정설계';
			}
			if (this.isIncludesKdcSecondaryKeyword) {
				return 'SW개발';
			}
			return this.isPt ? this[CAMP_CHARM_ITEM].job_bottom_category : this[CAMP_CHARM_ITEM].top_category;
		},
		gtmCommonParameters() {
			return {
				...this.$_userInfoForGtm,
				where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.HOME,
				product_name: GTM_COMMUNITY_AD_PARAMETERS.PRODUCT_NAME.CALLOUT,
				page_depth: 1,
				order: 1,
				inventory_number: 1,
				category: this[CASE_GTM_CATEGORY],
				agency_name: null,
				client_name: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_EDU,
				job_posting_id: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_EDU + '-230921',
			};
		},
		showNativeAdCard() {
			return getTimeInAsia().isBetween('2023-07-17', '2023-08-25', 'day', '[]');
		},
		hasAffiliateUnivExposure() {
			return Boolean(this[AFFILIATE_UNIV_EXPOSURE_DATA]?.partner);
		},
		affiliateDisplayUnivName() {
			const univName = this[AFFILIATE_UNIV_EXPOSURE_DATA]?.partner ?? '';
			return univName.split(' ')[0];
		},
	},
	mounted() {
		this.triggerGTMImpression();
	},
	methods: {
		handleClickBanner() {
			if (this.hasAffiliateUnivExposure) {
				this.clickAffiliateUniv();
				return;
			}

			const bannerTo = this.isPt ? 'viewEduDetail_CBC' : 'viewEduDetail';

			this.triggerGTMClickAdBanner();
			this.$_clickEduBanner({
				user_no: this.userNo,
				user_type: this.userType,
				email: this.userId,
				banner_type: this.showNativeAdCard ? 'basic' : 'native',
				banner_at: 'onboardCaseList',
				banner_to: bannerTo,
				camp_id: null,
				group_id: this.isPt ? this[CAMP_CHARM_ITEM].id : this[CAMP_CHARM_ITEM].no,
				local_storage_id: this.comentoLocalStorageId,
			});
			this.$_routeMixin_open_page(this.computedUrl);
		},
		triggerGTMClickAdBanner() {
			// GTM - clickAdBanner
			this.$_clickAdBanner({
				...this.gtmCommonParameters,
			});
		},
		triggerGTMImpression() {
			// GTM - impression
			this.$_impression({
				...this.gtmCommonParameters,
			});
		},
		clickAffiliateUniv() {
			this.$_clickCommunityToEdu_B2BList({
				...this.$_userInfoForGtm,
				where: 'onboardCaseList',
				type: 'banner',
				contractNo: this[AFFILIATE_UNIV_EXPOSURE_DATA].contractNo,
				partner_name: this[AFFILIATE_UNIV_EXPOSURE_DATA].partner,
				link: `/edu/univ/${this[AFFILIATE_UNIV_EXPOSURE_DATA].partnerUrl}`,
			});
			if (this.IS_MOBILE) {
				moveToLaravelPage(`/edu/univ/${this[AFFILIATE_UNIV_EXPOSURE_DATA].partnerUrl}`);
			} else {
				this.$_routeMixin_open_page(`${baseUrl}/edu/univ/${this[AFFILIATE_UNIV_EXPOSURE_DATA].partnerUrl}`);
			}
		},
	},
	components: { Skeleton, CaseListEduCampBannerContents },
};
