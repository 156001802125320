
import { GTM_COMMUNITY_AD_PARAMETERS } from '@/constants/community/gtm';
import { saraminUtmLink } from '@/constants/edu';

import { cdnBaseUrl, CASE_CATEGORY_JOB_SEEKING } from '@/plugins/constants';

import { CASE_CATEGORY, mapGettersOfCase, CASE_CATEGORY_GROUP_ID, CASE_GTM_CATEGORY } from '@/store/case';
import { mapGettersOfSearchList, SEARCH_KEYWORD } from '@/store/search/list';
import { GET_USER_META_INTEREST_DEPARTMENT, mapGettersOfUserMeta } from '@/store/userMeta';

import CaseListClassPtBanner from '@/components/case/list/main/atoms/CaseListClassPtBanner.vue';
import CaseListNativeAdTestBanner from '@/components/case/list/main/atoms/CaseListNativeAdTestBanner.vue';
import CaseListEduCampBanner from '@/components/case/list/main/molecules/CaseListEduCampBanner.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import userInfoMixin from '@/mixins/userInfoMixin';
import webinarPathMixin from '@/mixins/webinarPathMixin';

export default {
	name: 'CaseListBannerSection',
	mixins: [webinarPathMixin, layoutMixin, gtmTriggerMixin, userInfoMixin],
	computed: {
		GTM_COMMUNITY_AD_PARAMETERS() {
			return GTM_COMMUNITY_AD_PARAMETERS;
		},
		...mapGettersOfCase([CASE_CATEGORY, CASE_CATEGORY_GROUP_ID, CASE_GTM_CATEGORY]),
		...mapGettersOfUserMeta([GET_USER_META_INTEREST_DEPARTMENT]),
		...mapGettersOfSearchList([SEARCH_KEYWORD]),
		isSearch() {
			return !!this[SEARCH_KEYWORD];
		},
		isShowVodBanner() {
			const showVodKeyword = ['CRM', '브레이즈', 'Braze', '마케팅', '광고'];
			const userMetaKeyword = ['마케팅', '광고', 'PM'];

			if (!this.isSearch) {
				return false;
			}

			if (this.isSearch) {
				return showVodKeyword.filter(word => this[SEARCH_KEYWORD].includes(word));
			}

			if (this[GET_USER_META_INTEREST_DEPARTMENT]) {
				return userMetaKeyword.filter(word => this[GET_USER_META_INTEREST_DEPARTMENT].includes(word));
			}
			return false;
		},
		bannerSrc() {
			return `${this.cdnBaseUrl}/images/banner/`;
		},
		saraminLink() {
			return saraminUtmLink(
				CASE_CATEGORY === CASE_CATEGORY_JOB_SEEKING ? 'community_job' : 'community_univ',
				this.IS_MOBILE,
			);
		},
		// 이직 고민, 랜선 사수, 실무스킬
		isShowPtBanner() {
			return [2, 3, 5].includes(this[CASE_CATEGORY_GROUP_ID]);
		},
		// 취업고민, 대학생고민
		isShowNativeEduAdTestBanner() {
			return [1, 4].includes(this[CASE_CATEGORY_GROUP_ID]);
		},
		// 실무고민, 이직고민, 직장인고민
		isShowNativePtAdTestBanner() {
			return [2, 3, 5].includes(this[CASE_CATEGORY_GROUP_ID]);
		},
	},
	created() {
		this.setConstants();
		this.NATIVE_EDU_AD_INFO = {
			ui: {
				no: 5447,
				user_no: 30036,
				other_inf: '직무경험이 없어 자소서, 면접 준비하기 어려웠던 경험이 있나요?',
				question:
					'인턴 구하기가 힘들다면 여름방학동안 5주 직무경험을 해보는 걸 추천드려요. 요즘에는 인턴도 경쟁이 심해서 인턴하기가 너무 어려운데 5주 직무경험은 어려운 인턴 지원 프로세스 없이 참여할 수 있거든요. 하반기 자소서, 면접이 고민이라면 여름방학에 대외활동을 해보시는건 어떨까요',
				link: '/edu',
			},
			gtm: {
				where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.ON_BOARD_CASE_LIST,
				product_name: GTM_COMMUNITY_AD_PARAMETERS.PRODUCT_NAME.NATIVE_TEXT,
				page_depth: 1,
				order: 1,
				inventory_number: 1,
				category: this.CASE_GTM_CATEGORY,
				agency_name: null,
				client_name: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_EDU,
				job_posting_id: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_EDU + '-230921',
			},
		};
		this.NATIVE_PT_AD_INFO = {
			ui: {
				no: 5447,
				user_no: 30036,
				other_inf: '실무 역량 때문에 고민하던 한달차 신입입니다.',
				question:
					'실무PT 수강생이 남긴 후기입니다. 사수가 있긴 하지만 너무 바쁘고..뭘 물어봐야 할지도 막막해 고민이 많았는데, 실무 프로세스 그대로 너무 세심하게 피드백 해주셔서 정말 큰 도움이 됐습니다. 라이브 참여할 때 질문을 정말 많이 했는데도 친절하고 상세하게 답변을 주셔서 감동이었어요! ',
				link: '/class/pt',
				target: '_blank',
			},
			gtm: {
				where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.ON_BOARD_CASE_LIST,
				product_name: GTM_COMMUNITY_AD_PARAMETERS.PRODUCT_NAME.NATIVE_TEXT,
				page_depth: 1,
				order: 1,
				inventory_number: 1,
				category: this.CASE_GTM_CATEGORY,
				agency_name: null,
				client_name: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_CBC,
				job_posting_id: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_CBC + '-230921',
			},
		};
	},
	methods: {
		setConstants() {
			this.cdnBaseUrl = cdnBaseUrl;
			this.CASE_CATEGORY_JOB_SEEKING = CASE_CATEGORY_JOB_SEEKING;
		},
		clickHomeBanner(link, order = 0) {
			this.$_clickHomeBanner({
				...this.$_userInfoForGtm,
				banner_type: 'middle',
				banner_order: order,
				category: this[CASE_CATEGORY] === null ? '홈' : this[CASE_CATEGORY].trim(),
				link,
			});
		},
	},
	components: {
		CaseListNativeAdTestBanner,
		CaseListClassPtBanner,
		CustomNuxtLink,
		CaseListEduCampBanner,
	},
};
