
import CaseListBannerBase from '@/components/case/list/main/atoms/CaseListBannerBase.vue';
import CaseListBannerMainCopy from '@/components/case/list/main/atoms/CaseListBannerMainCopy.vue';

import gtmTriggerMixin from '@/mixins/gtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

export default {
	name: 'CaseListToClassPtLandingBannerBase',
	mixins: [gtmTriggerMixin, userInfoMixin, routeMixin, layoutMixin],
	props: {
		imgSrc: {
			type: String,
			default: '',
		},
		imgAlt: {
			type: String,
			default: '',
		},
		mainCopy: {
			type: String,
			default: '',
		},
	},
	components: { CaseListBannerMainCopy, CaseListBannerBase },
};
