
import CommunityAdCard from '@/components/case/list/main/molecules/CommunityAdCard.vue';
import CustomNuxtLink from '@/components/CustomNuxtLink.vue';

import appCommandMixin from '@/mixins/appCommandMixin';
import gtmCommunityAdMixin from '@/mixins/gtm/gtmCommunityAdMixin';
import routeMixin from '@/mixins/routeMixin';

export default {
	name: 'CaseListNativeAdTestBanner',
	mixins: [appCommandMixin, routeMixin, gtmCommunityAdMixin()],
	props: {
		item: {
			type: Object,
			default: () => {},
			required: true,
		},
	},
	components: { CommunityAdCard, CustomNuxtLink },
};
