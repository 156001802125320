
import { GTM_COMMUNITY_AD_PARAMETERS } from '@/constants/community/gtm';
import { CLASS_PT_PATH } from '@/constants/paths';

import { cdnBaseUrl } from '@/plugins/constants';

import { CASE_GTM_CATEGORY, IS_CAREER_CHANGE, mapGettersOfCase } from '@/store/case';

import CaseListToClassPtLandingBannerBase from '@/components/case/list/main/atoms/CaseListToClassPtLandingBannerBase.vue';

import communityGtmTriggerMixin from '@/mixins/gtm/communityGtmTriggerMixin';
import layoutMixin from '@/mixins/layoutMixin';
import routeMixin from '@/mixins/routeMixin';
import userInfoMixin from '@/mixins/userInfoMixin';

const BANNER_SRC = `${cdnBaseUrl}/images/banner/`;

export default {
	name: 'CaseListToClassPtLandingBanner',
	mixins: [userInfoMixin, layoutMixin, communityGtmTriggerMixin, routeMixin],
	computed: {
		...mapGettersOfCase([IS_CAREER_CHANGE, CASE_GTM_CATEGORY]),
		mainCopyByCaseListCategory() {
			return this[IS_CAREER_CHANGE]
				? `이직 전, ${this.IS_MOBILE ? '<br/>' : ''}현직자 라이브 트레이닝으로
			${this.IS_MOBILE ? '<br/>' : ''}실무 역량 제대로 채워보세요!`
				: `잘하는 현직자가 일하는 방식, ${this.IS_MOBILE ? '<br/>' : ''}라이브로 배워 내 실무에 적용해요!`;
		},
		gtmCommonParameters() {
			return {
				...this.$_userInfoForGtm,
				where: GTM_COMMUNITY_AD_PARAMETERS.WHERE.ON_BOARD_CASE_LIST,
				product_name: GTM_COMMUNITY_AD_PARAMETERS.PRODUCT_NAME.IMAGE,
				page_depth: 1,
				order: 1,
				inventory_number: 1,
				category: this[CASE_GTM_CATEGORY],
				agency_name: null,
				client_name: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_CBC,
				job_posting_id: GTM_COMMUNITY_AD_PARAMETERS.CLIENT_NAME.COMENTO_CBC + '-230921',
			};
		},
	},
	created() {
		this.BANNER_SRC = BANNER_SRC;
		this.CLASS_PT_PATH = CLASS_PT_PATH;
	},
	mounted() {
		this.triggerGTMImpression();
	},
	methods: {
		goToPtLanding() {
			this.$_routeMixin_go_page(CLASS_PT_PATH);
		},
		triggerGTMClickAdBanner() {
			// GTM - clickAdBanner
			this.$_clickAdBanner({
				...this.gtmCommonParameters,
			});
		},
		handleClickClassPtLandingBanner() {
			this.triggerGTMClickAdBanner();
			this.goToPtLanding();
		},
		triggerGTMImpression() {
			// GTM - impression
			this.$_impression({
				...this.gtmCommonParameters,
			});
		},
	},
	components: { CaseListToClassPtLandingBannerBase },
};
