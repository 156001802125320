
import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CaseListBannerMainCopy',
	mixins: [layoutMixin],
	props: {
		text: {
			type: String,
			default: '',
		},
	},
};
