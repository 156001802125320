
import { cdnBaseUrl } from '@/plugins/constants';
import { YMDWithDots, formatDate } from '@/plugins/filters/formatDate';

import layoutMixin from '@/mixins/layoutMixin';

export default {
	name: 'CommunityAdCard',
	mixins: [layoutMixin],
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		title: {
			type: [String, Function],
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		thumbnail: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			cdnBaseUrl,
		};
	},
	computed: {
		getCardBackgroundImage() {
			const path = this.thumbnail ?? `${cdnBaseUrl}/images/meta/meta-landing.jpg`;
			const width = this.IS_MOBILE ? 400 : 260;
			return `${path}?s=${width}`;
		},
		getContentWithEllipsis() {
			const maxLength = this.IS_MOBILE ? 50 : this.thumbnail ? 110 : 140;
			const content = this.$_removeHTMLTag(this.content)
				.replace(/&nbsp;/g, '')
				.replace(/\n/g, '');
			const moreText = `...<span class="more-text">더보기</span>`;

			if (content.length > maxLength) {
				// 단어별로 자르고 길면 '...더보기' 붙이기
				const sliceContent = String(content).slice(0, maxLength);
				const lastSpaceCharIndex = sliceContent.search(/ [^ ]*$/);
				return `${sliceContent.slice(0, lastSpaceCharIndex)}${moreText}`;
			}
			if (content.length === 0) {
				return moreText;
			}
			return content;
		},
	},
	created() {
		this.YMDWithDots = YMDWithDots;
	},
	filters: {
		formatDate,
	},
};
