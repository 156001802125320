import userInfoMixin from '@/mixins/userInfoMixin';

const webinarPathMixin = {
	mixins: [userInfoMixin],
	methods: {
		getWebinarUrl(buttonType) {
			const notionUrl = `https://bit.ly/37b1SSX`;
			return `${notionUrl}?button=${buttonType}&user_type=${this.userType}`;
		},
	},
};

export default webinarPathMixin;
